import React, {useEffect} from "react";
import {Box, Grommet, Paragraph} from "grommet";
import {LOODS25} from "./Theme_Loods25"
import './loods-webshop.css';
import loods25Background from '../media/videos/loods-background.mp4';

export const LoodsWebshop = (props) => {

    useEffect(() =>{
                if (document.body.getElementsByClassName("ot-container").length === 0){
                    console.log("run shop scripts");
                    const eventixScript = document.createElement('script');
                    eventixScript.src = "https://shop.eventix.io/build/integrate.js"
                    eventixScript.async = false;
                    document.body.appendChild(eventixScript);
                }
                else {
                    console.log("Skip shop scripts")
                }
        });
   
return (
    <Grommet 
    className="grommet-box"
    theme={LOODS25}
    style={{}}>
        <video id='loods25-background' className='webshop-promo' autoPlay loop muted playsInline >
            <source src={loods25Background} type='video/mp4' />
        </video>
        <Box id="loods25-container"
        direction="row" 
        align="center"
        justify="center"
        flex="shrink">
           
            <Box
                pad="medium"
                align="center"
                animation= "slideDown"
                className="infobox">
                <Paragraph size="26px" textAlign='left' className="steps">
                    BEATS IN THE LOODS<br/>
                    <br/>
                    Voordat de wereld weer groen kleurt willen we nog één keer met jullie raven. Hiervoor hebben wij een perfecte loods gevonden.<br/><br/>
                    Wanneer: zaterdag 15 maart<br/><br/>
                    Tijd: vanaf 17.00 uur - iedereen moet voor 20.00 uur binnen zijn! (Dit ivm de veiligheid) Dansen tot: 2.00 uur <br/><br/>
                    Waar: Utrecht Noord - 17 min fietsen vanaf CS - De exacte locatie ontvang je nadat je een ticket hebt gekocht.   <br/><br/>
                    Entreeticket: € 11,50 excl servicekosten  <br/><br/>
                    Drinken: Bring your own Booze - Geen glas! je snapt waarom <br/><br/>
                    Eten: Bring Your Own - voor wat snacks&fruit wordt gezorgd
                </Paragraph>
            </Box>
            <Box
                align="center"
                className="shoppa">
                <div id="shop-frame" className="eventix-shop"
                    data-url="https://shop.eventix.io/db007949-8dd0-4402-9c67-cb511833e855"
                    style={{maxWidth: "600px"}}>
                </div>
            </Box>
            
            
        </Box>
        
    </Grommet>
    
);
}
export default LoodsWebshop;